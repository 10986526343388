.intrested-locations-wrap {
  .heading-question {
    font-weight: 400;
    font-size: 29px;
    line-height: 51px;
    text-align: center;
    color: #504f4f;
    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 35px;
    }
    .font-weight-bold {
      font-weight: 500;
    }
  }
  .location-container {
    .ant-input-affix-wrapper-lg {
      border: 1px solid #d4d4d4;
      box-sizing: border-box;
      border-radius: 5px;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      color: rgba(62, 76, 99, 0.65);
    }
    .ant-input-affix-wrapper-focused {
      box-shadow: unset;
    }
    .locationAddIcon {
      border-left: unset;
    }
  }
  .locationInterestList {
    &::-webkit-scrollbar {
      width: 4px;
      background: #dadada;
      border-radius: 50px;
    }
    &::-webkit-scrollbar-thumb {
      background: #16264238;
      border-radius: 50px;
    }
    .locationRow {
      border-bottom: solid 1px #d9d9d9;
      padding: 10px 0;
      background: #f5f7f2;
      font-size: medium;

      .locationName {
        font-weight: 400;
        font-size: 16px;
        line-height: 2;
        color: #515151;
        width: unset;
      }
      .circle-close {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: transparent;
        cursor: pointer;
        // border: solid 1px red;
        margin-right: 15px;

        .close-icon {
          color: #ba703b;
          font-size: 22px;
        }
      }
    }
  }
  .circle-close {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: transparent;
    cursor: pointer;
    margin-left: 15px;

    .close-icon {
      // color: #ba703b;
      font-size: 22px;
    }
  }

  // .locationInterestList::-webkit-scrollbar {
  //   display: block;
  // }

  .headquartered-wrapper {
    column-gap: 16px;
    row-gap: 16px;
    padding-left: 20px;
    margin-bottom: 40px;
    max-width: 840px;
    width: 100%;
    .headquartered-btn {
      width: 190px;
      height: 74px;
      padding: 10px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 12px;
        color: #425a85;
      }
      @media (min-width: 992px) and (max-width: 1200px) {
        width: 210px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        width: 240px;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
      .headquartered-btn-text {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #504f4f;
      }
    }
    .selected-headquartered-btn {
      background: #fafbf8;
      border: 2px solid #7c8e54;
      box-sizing: border-box;
      border-radius: 5px;
      svg {
        color: #7c8e54;
      }
    }
  }
}
